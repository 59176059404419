import React from 'react';
import ProductPageTemplate from '../../components/ProductPageTemplate'

import bauelemente_00 from '../../images/bauelemente_00_1600.webp'
import bauelemente_01 from '../../images/bauelemente_01_1600.webp'
import bauelemente_02 from '../../images/bauelemente_02_1600.webp'
import bauelemente_03 from '../../images/bauelemente_03_1600.webp'
import bauelemente_thumb_00 from '../../images/bauelemente_00_400.webp'
import bauelemente_thumb_01 from '../../images/bauelemente_01_400.webp'
import bauelemente_thumb_02 from '../../images/bauelemente_02_400.webp'
import bauelemente_thumb_03 from '../../images/bauelemente_03_400.webp'

const context = {
  title: 'Bauelemente',
  description: 'Individuelle Festverglasungen geeignet für Neubau oder Modernisierung, angepasst an in Ihre Einbausituation',
  text: 'Unsere hochwertigen Festverglasungen rahmen Ihre neue Automatikschiebetür ein und verleihen Ihrem Gebäude ein erstklassiges Erscheinungsbild. Die Rahmenelemente bestehend aus Seitenteil- und Oberlichtverglasung ist besonders Robustheit gegen Witterungseinflüsse und hat durch thermisch getrennte Profile gängiger Systemhersteller (Schüco, Wicona, Heroal, etc.) eine hohe Energieeffizienz und ausgezeichnete Wärmedämmeigenschaften.',
  features: [
    //'autschr',
    //'barrierefreiheit',
    //'brandschutz',
    //'breakOut',
    //'design',
    //'din18650',
    'energieeinsparung',
    'modernisierung',
    //'notStrom',
    //'personenfrequenz',
    //'platzsparend',
    'transparenz',
    'einbruchhemmung',
    //'zutrittskontrolle',
    //'fluchtweg',
  ],
  legalRequirements: [
    'autschr',
    'din18650',
    'dinEn170',
    'eltvtr',
  ],
  carouselImages: [
    {
      original: bauelemente_00,
      thumbnail: bauelemente_thumb_00,
      originalAlt: 'Bauelemente'
    },
    {
      original: bauelemente_01,
      thumbnail: bauelemente_thumb_01,
      originalAlt: 'Bauelemente'
    },
    {
      original: bauelemente_02,
      thumbnail: bauelemente_thumb_02,
      originalAlt: 'Bauelemente'
    },
    {
      original: bauelemente_03,
      thumbnail: bauelemente_thumb_03,
      originalAlt: 'Bauelemente'
    },
  ],
  configurator: false,
}

const Page = () => {

  return(
    <ProductPageTemplate pageContext={context} />
  )
}

export default Page;
